import React, { useState, useEffect } from "react";
import cs from "classnames";
import { PersonalLicense } from "./PersonalLicense/PersonalLicense";
import { OrgLicense } from "./OrgLicense/OrgLicense";
import { useQuery } from "hooks/useQuery";
import { LoadingRobo } from "components/Loading";
import { Prompt } from "react-router-dom";
import { Switch } from "./Switch";

const defaultPrice = {
  enabled: false,
  tiers: [{ minQuantity: 1, price: 10 }],
};

const createPrices = () => {
  return {
    lifetime: defaultPrice,
    monthly: defaultPrice,
    yearly: defaultPrice,
  };
};

const { REACT_APP_API_URL } = process.env;

const fetchPackage = (packageName) => {
  return fetch(
    `${REACT_APP_API_URL}/packages/get/scoped/package?packageName=${packageName}`,
    {
      headers: {
        token: localStorage.getItem("token") || sessionStorage.getItem("token"),
      },
    }
  ).then((resp) => {
    if (resp.ok) return resp.json();
    resp.text().then((e) => {
      throw e;
    });
  });
};

const fetchData = (packageName) =>
  fetch(`${REACT_APP_API_URL}/v2/price?packageName=${packageName}`, {
    method: "GET",
    headers: {
      token: localStorage.getItem("token"),
    },
  }).then((resp) => {
    if (resp.ok) return resp.json();
    throw resp.text();
  });

export const AdvancedPricing = () => {
  const [personalPricing, setPersonalPricing] = useState(createPrices());
  const [orgPricing, setOrgPricing] = useState(createPrices());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [apiResp, setApiResp] = useState({});
  const [isForSale, setIsForSale] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false)
  const query = useQuery();
  const packageName = query.get("pkg");

  useEffect(() => {
    const fetchPrices = async (packageName) => {
      const prices = await fetchData(packageName);
      if (prices?.orgLicense) {
        setOrgPricing(prices.orgLicense);
        setPersonalPricing(prices.personalLicense);
      }
      setIsLoading(false);
      setApiResp(prices);
    };
    const fetchPackageDetails = async (packageName) => {
      const pkg = await fetchPackage(packageName);
      setIsForSale(pkg.isForSale);
    };
    fetchPrices(packageName);
    fetchPackageDetails(packageName);
  }, []);

  useEffect(() => {
    if (
      personalPricing.lifetime.enabled === false &&
      personalPricing.monthly.enabled === false &&
      personalPricing.yearly.enabled === false &&
      orgPricing.yearly.enabled === false &&
      orgPricing.monthly.enabled === false &&
      orgPricing.lifetime.enabled === false
    ) {
      setIsForSale(false)
    }

    setIsFormDirty(true)
  }, [orgPricing, personalPricing])

  const handleSave = async () => {
    setIsSubmitting(true);
    await fetch(`${REACT_APP_API_URL}/v2/price?packageName=${packageName}`, {
      method: "POST",
      headers: {
        token: localStorage.getItem("token"),
        "content-type": "application/json",
      },
      body: JSON.stringify({
        personalLicense: personalPricing,
        orgLicense: orgPricing,
        isForSale,
      }),
    }).then((resp) => {
      if (resp.ok) return resp.json();
      throw resp.text();
    });
    setIsSubmitting(false);
    setIsFormDirty(false)
  };

  const ConfigBtns = ({ className }) => {
    return (
      <div className={cs("flex gap-4", className)}>
        <div className="bg-white rounded flex items-center justify-between py-2 px-4">
          <p className="font-semibold mr-4">Enable sales?</p>
          <Switch
            checked={isForSale}
            onChange={(e) => setIsForSale(e.target.checked)}
          />
        </div>
        <button
          onClick={handleSave}
          className="bg-indigo-600 font-semibold px-20 py-2 rounded text-white"
          // disabled={isSubmitting}
        >
          {isSubmitting ? "SAVING..." : "SAVE"}
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="md:ml-56 bg-gray-200 min-h-screen">
        {/* Blank header */}
        <div className="invisible bg-white md:visible h-12 py-2 w-full shadow-xs"></div>

        {/* Content */}
        <div className="p-4">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl font-bold">
              Advanced Pricing{" "}
              <span className="text-sm bg-white text-gray-500 rounded-3xl py-1 px-2">
                beta
              </span>
            </h1>
            <ConfigBtns className="hidden lg:flex" />
          </div>
          <p className="mb-6">
            Use this page to configure tiered pricing options
          </p>

          {isLoading ? (
            <LoadingRobo />
          ) : (
            <>
              <h2 className="text-2xl font-bold mb-2 mt-4">
                Individual license
              </h2>

              <PersonalLicense
                pricing={personalPricing}
                onChange={setPersonalPricing}
              />

              <div className="border-t border-gray-400 shadow-sm my-12" />
              <h2 className="text-2xl font-bold mb-2">Organization license</h2>

              <OrgLicense pricing={orgPricing} onChange={setOrgPricing} />
            </>
          )}

          <div className="lg:hidden my-4 bg-gray-300 rounded-lg p-4">
            <ConfigBtns className="grid grid-cols-2" />
          </div>

        </div>
      </div>
      <Prompt
        when={isFormDirty}
        message="There could be unsaved changes, are you sure you want to leave?"
      />
    </>
  );
};
