import React from 'react'
import { USDInput } from "components/ConfigurePackage/USDInput";
import { Switch } from "../Switch";
import { Lifetime } from './lifetime';
import { Monthly } from './monthly';
import { Yearly } from './yearly';

export const PersonalLicense = ({ pricing, onChange }) => {
  const handleChanges = (key) => (value) => {
    onChange({
      ...pricing,
      [key]: value
    })
  }
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-6">
      <Lifetime pricing={pricing.lifetime} onChange={handleChanges('lifetime')} />
      <Monthly pricing={pricing.monthly} onChange={handleChanges('monthly')} />
      <Yearly pricing={pricing.yearly} onChange={handleChanges('yearly')} />
    </div>
  );
};
