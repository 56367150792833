import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

import "@fortawesome/fontawesome-free/css/all.min.css";
import HandLoader from 'components/HandLoader'
import Sidebar from "components/Sidebar.js";
import { Spinner } from "components/Loading";
import { License } from "views/License";
import { AdvancedPricing } from "views/AdvancedPricing/AdvancedPricing";

const Dashboard = React.lazy(() => import("views/Dashboard.js"));
const MyPackageDetail = React.lazy(() => import("views/MyPackageDetail"));
const PackageDetail = React.lazy(() => import("views/PackageDetail.js"));
const Login = React.lazy(() => import("views/Login.js"));
const Signup = React.lazy(() => import("views/Signup.js"));
const MyAccount = React.lazy(() => import("views/MyAccount.js"));
const Contact = React.lazy(() => import("views/Contact.js"));
const FAQ = React.lazy(() => import("views/FAQ.js"));
const Roadmap = React.lazy(() => import("views/Roadmap.js"));
const Forgot = React.lazy(() => import("views/Forgot.js"));
const Upgrade = React.lazy(() => import("views/Upgrade.js"));
const ResetPassword = React.lazy(() => import("views/ResetPassword.js"));
const PrivacyPolicy = React.lazy(() => import("views/PrivacyPolicy.js"));
const TOS = React.lazy(() => import("views/TermsOfUse.js"));
const Explore = React.lazy(() => import("views/Explore.js"));
const StripeConnect = React.lazy(() => import("views/StripeConnect.js"));
const PublicPackageDetail = React.lazy(() => import("views/PublicPackageDetail.js"));
const PublicPackageExplore = React.lazy(() => import("views/PublicPackageExplore.js"));

/* Initialize analytics */
const analytics = Analytics({
  app: "privjs-dashboard",
  plugins: [
    googleAnalytics({
      trackingId: "UA-164577886-1",
    }),
  ],
});

function DashBoardLoader () {
  return (
    <div className="md:ml-56 bg-gray-200 min-h-screen">
      <div className="invisible bg-white md:visible h-12 py-2 w-full shadow-xs" />
      <div className="flex justify-center mt-2">
        <Spinner />
      </div>
    </div>
  );
}

const App = () => {
  const history = useHistory();
  analytics.page();

  history.listen(() => {
    analytics.page();
  });

  return (
    <Suspense fallback={HandLoader}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot" component={Forgot} />
        <Route path="/upgrade/:sessionId" component={Upgrade} />
        <Route path="/reset/:token" component={ResetPassword} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-of-use" component={TOS} />
        <Route path="/license" component={License} />
        <Route path="/stripe/connect" component={StripeConnect} />
        <Route
          path="/buy/:packageName/purchase/:sessionId"
          component={PublicPackageDetail}
        />
        <Route path="/buy/packageDetail" component={PublicPackageDetail} />
        <Route path="/buy" component={PublicPackageExplore} />
        <>
          <Sidebar />
          <Suspense fallback={<DashBoardLoader />}>
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/explore" component={Explore} />
              <Route path="/account" component={MyAccount} />
              <Route path="/upgrade" component={Upgrade} />
              <Route path="/contact" component={Contact} />
              <Route path="/faq" component={FAQ} />
              <Route path="/roadmap" component={Roadmap} />
              <Route exact path="/my-package" component={MyPackageDetail} />
              <Route exact path="/package" component={PackageDetail} />
              <Route path="/package/:pkgName/purchase/:sessionId" component={PackageDetail} />
              <Route path="/package/:pkgName" component={PackageDetail} />
              <Route path="/package" component={PackageDetail} />
              <Route path="/configure-pricing" component={AdvancedPricing} />
              <Redirect from="/" to="/buy" />
            </Switch>
        </Suspense>
        </>
      </Switch>
    </Suspense>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
