import { USDInput } from "components/ConfigurePackage/USDInput";
import React from "react";
import { Switch } from "../Switch";

export const Lifetime = ({ pricing, onChange }) => {
  const handlePriceUpdate = (e) => {
    onChange({
      ...pricing,
      tiers: [{ minQuantity: 1, price: e.target.value }],
    });
  };
  return (
    <div className="bg-gray-300 p-4 rounded-lg">
      <div className="bg-white rounded flex items-center justify-between py-2 px-4">
        <p className="font-semibold">Lifetime license</p>
        <Switch
          checked={pricing.enabled}
          onChange={(e) => onChange({ ...pricing, enabled: e.target.checked })}
        />
      </div>
      <table className="table-fixed border-collapse text-left mt-4">
        <thead>
          <tr>
            <th className="w-4/12 font-semibold">Min Quantity</th>
            <th className="w-7/12 font-semibold">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="pr-3">
              <input
                className="w-full bg-gray-100 text-gray-500 form-input"
                type="number"
                title="You cannot edit this quantity"
                disabled
                value="1"
              />
            </td>
            <td>
              <USDInput
                value={pricing.tiers[0].price}
                onChange={handlePriceUpdate}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
