import React, { useState } from "react";
import cs from "classnames";
import { USDInput } from "components/ConfigurePackage/USDInput";
import { Switch } from "../Switch";
import { OrgMonthly } from "./monthly";
import { OrgYearly } from "./yearly";
import { Lifetime } from "./lifetime";

export const OrgLicense = ({ pricing, onChange }) => {
  const handleChanges = (key) => (value) => {
    onChange({
      ...pricing,
      [key]: value
    })
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-6">
      <Lifetime pricing={pricing.lifetime} onChange={handleChanges('lifetime')} />

      <OrgMonthly pricing={pricing.monthly} onChange={handleChanges('monthly')} />
      <OrgYearly pricing={pricing.yearly} onChange={handleChanges('yearly')} />
    </div>
  );
};
