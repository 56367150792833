import React from "react";
import cs from "classnames";

export const Switch = ({ checked, onChange }) => {
  return (
    <label>
      <input
        hidden
        className="hidden"
        type="checkbox"
        id="checkbox-is-for-sale"
        checked={checked}
        onChange={onChange}
      />
      <span
        className={cs(
          "border rounded-full flex items-center cursor-pointer w-12",
          {
            "bg-green-400 justify-end": checked,
            "bg-gray-300 justify-start border-gray-400": !checked,
          }
        )}
      >
        <span className="rounded-full border w-6 h-6 shadow-inner bg-white"></span>
      </span>
    </label>
  );
};
