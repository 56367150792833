import React from 'react'

export const USDInput = ({ value, onChange }) => {
  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span className="text-gray-600 sm:text-sm sm:leading-5">
          $
        </span>
      </div>
      <input
        id="price"
        className="form-input block w-full py-2 pl-6 sm:text-sm sm:leading-5 border rounded"
        placeholder="0.00"
        type="number"
        value={value}
        min={0}
        onChange={onChange}
      />
    </div>
  )
}
