import { USDInput } from "components/ConfigurePackage/USDInput";
import React from "react";
import cs from "classnames";
import { Switch } from "../Switch";

export const OrgYearly = ({ pricing, onChange }) => {
  const handleYearlyUpdate = (index, key) => (e) => {
    const pricesCopy = [...pricing.tiers];
    pricesCopy[index] = {
      ...pricesCopy[index],
      [key]: e.target.value,
    };

    onChange({
      enabled: pricing.enabled,
      tiers: [...pricesCopy.sort((a, b) => a.minQuantity - b.minQuantity)],
    });
  };

  const addYearlyPrice = () => {
    onChange({
      ...pricing,
      tiers: [
        ...pricing.tiers,
        {
          minQuantity:
            pricing.tiers[pricing.tiers.length - 1].minQuantity + 1,
          price: pricing.tiers[pricing.tiers.length - 1].price,
        },
      ],
    });
  };
  const deleteYearlyPrice = (index) => () => {
    pricing.tiers.splice(index, 1);
    onChange({
      ...pricing,
      tiers: pricing.tiers,
    });
  };

  return (
    <div className="bg-gray-300 p-4 rounded-lg">
      <div className="bg-white rounded flex items-center justify-between py-2 px-4">
        <p className="font-semibold">Yearly Subscription</p>
        <Switch
          checked={pricing.enabled}
          onChange={(e) => onChange({ ...pricing, enabled: e.target.checked })}
        />
      </div>
      <table className="table-fixed border-collapse text-left mt-4">
        <thead>
          <tr>
            <th className="w-4/12 font-semibold">Min Quantity</th>
            <th className="w-7/12 font-semibold">Price per license</th>
            <th className="w-1/12"></th>
          </tr>
        </thead>
        <tbody>
          {pricing.tiers.map((price, index) => {
            return (
              <tr>
                <td className="pr-3">
                  <input
                    className={cs("w-full form-input outline-none", {
                      "bg-gray-100 text-gray-500": price.minQuantity === 1,
                    })}
                    type="number"
                    disabled={price.minQuantity === 1}
                    value={price.minQuantity}
                    onChange={handleYearlyUpdate(index, "minQuantity")}
                  />
                </td>
                <td>
                  <USDInput
                    value={price.price}
                    onChange={handleYearlyUpdate(index, "price")}
                  />
                </td>
                <td className="pl-2">
                  <button
                    className={cs(
                      "bg-gray-100 text-gray-500 rounded px-4 py-1",
                      {
                        "cursor-not-allowed hidden": price.minQuantity === 1,
                      }
                    )}
                    onClick={deleteYearlyPrice(index)}
                  >
                    <i className="fas fa-trash text-gray-500" />
                  </button>
                </td>
              </tr>
            );
          })}

          <tr>
            <td></td>
            <td className="text-right py-2">
              <button
                className="border border-gray-500 hover:bg-gray-400 rounded px-4 py-1"
                onClick={addYearlyPrice}
              >
                Add Price +
              </button>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
