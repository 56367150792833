import Navbar from 'components/Navbar'
import React from 'react'

export const License = () => {
  return (
    <>
      <Navbar transparent />
      <main className="bg-gray-200">
        <div className="min-h-screen flex justify-center py-12 px-4 sm:px-6 lg:px-8 py-12">
          <div className="max-w-xl w-full">
            <h1 className="text-3xl font-semibold">NON-EXCLUSIVE SOFTWARE LICENSE AGREEMENT</h1>
            <hr/>
            <h2 className="mt-8 text-xl font-semibold">
              INTRODUCTION
            </h2>
            <p className="my-2">
              This is a legal agreement between you (either an individual or a single entity) and PrivJs.com (Prasanna Mestha, Sole Proprietor) for all the code purchased/installed via privjs.com or it's products, copyrighted code including the code uploaded by our Users.
            </p>

            <h2 className="mt-8 text-xl font-semibold">
              LICENSE
            </h2>
            <p className="my-2">
              <ul className="list-disc">
                <li>Subject to the terms and conditions of this Agreement, PrivJs hereby grants you a non-exclusive, worldwide, non-transferable license to authorize a total of 1 individual ("User") to use the PROGRAM as a tool for the development of web sites, apps, games, components and other software applications ("Developed Works").</li>
                <li>Subject to the terms and conditions of this Agreement, PrivJs hereby grants you a non-exclusive, worldwide, non-transferable license to authorize a total of 'n' individuals ("Users") where 'n' refers to the quantity of the purchase to use the PROGRAM as a tool for the development of web sites, apps, games, components and other software applications ("Developed Works").</li>
              </ul>
            </p>

            <h2 className="mt-8 text-xl font-semibold">
              LIMITATION OF LICENSE AND RESTRICTIONS
            </h2>
            <p className="my-2">
              <ol className="list-decimal">
                <li>You agree that you will not sell, rent, or license the PROGRAM's source code or any derivative works thereof to any third party without the prior written consent of PrivJs and the designated code owner. Distribution of the PROGRAM as part of your Developed Work is acceptable so long as it is used exclusively as a part of your Developed Work. You agree not to modify or delete any code and existing copyright notices located in the source code purchased via PrivJs.</li>
                <li>You may use, duplicate, and distribute the compiled object code as embedded in Developed Works created by you, either for your own use or for distribution to a third party so long as end users of the Developed Work are not being charged for the source code that you purchased via PrivJs.</li>
                <li>You may make modifications to the source code exclusively for your own use in order to perform bug fixes or other minor edits required to operate the PROGRAM as originally intended.</li>
              </ol>
            </p>

            <h2 className="mt-8 text-xl font-semibold">
              TITLE AND OWNERSHIP
            </h2>
            <p className="my-2">
              Users can only purchase the license to use the code/packages but not to re-distribute the same. You acknowledge that no title to the intellectual property in the PROGRAM is transferred to you. You further acknowledge that title and full ownership rights to the PROGRAM, including all intellectual property rights therein, will remain the exclusive property of PrivJs and  you will not acquire any rights to the PROGRAM except as expressly set forth in this Agreement. You agree that any copies of the PROGRAM you make will contain the same proprietary notices which appear on and in the PROGRAM. You agree that PrivJs may identify you as a licensee unless you make a written request otherwise. PrivJs hereby grants to you the right to disclose that your app, product, game, component, or other Developed Work makes use of code purchased via PrivJs (for example, "Distributed by PrivJs"). No right, title or license to any Developed Works is granted to PrivJs under this Agreement.
            </p>

            <h2 className="mt-8 text-xl font-semibold">
              TERMINATION
            </h2>
            <p className="my-2">
              The code-owners of PrivJs reserve the right to terminate the license at their discretion. PrivJs also reserves the right to terminate the license if it detects any breach of this agreement, non-payment of fees or any other reasons. A written notice may or may not be provided depending upon the circumstances.
            </p>

            <h2 className="mt-8 text-xl font-semibold">
              Disputes
            </h2>
            <p className="my-2">
              In case of any disputes, the User may contact PrivJs via e-mail to support@privjs.com addressing the concern and request a resolution.
            </p>
          </div>
        </div>
      </main>
      </>
  )
}
